import React, { lazy, Suspense } from 'react';
import { getAuth } from "firebase/auth";
import { initializeApp } from "firebase/app";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import './components/catalogue/Catalogue.css';
import './components/events/Sorted.css';

import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


import logo from './assets/logo.png';

//file imports
// import Login from './components/launch/Login';
// import Home from './components/home/Home';
import Topbars from './components/main/Topbars';
// import Gallery from './components/gallery/Gallery';
// import Catalogue from './components/catalogue/Catalogue';
// import Boat from './components/boat/Boat';
// import Events from './components/events/Events';
// import Event from './components/events/Event';
// import Sorted from './components/events/Sorted';
// import Contacts from './components/contacts/Contacts';
// import Community from './components/community/Community';
// import NotFound from './components/notFound/NotFound';
// import Picture from './components/picture/Picture';
import Footer from './components/footer/Footer';


const Home = lazy(() => import('./components/home/Home'));
const Gallery = lazy(() => import('./components/gallery/Gallery'));
const Catalogue = lazy(() => import('./components/catalogue/Catalogue'));
const Boat = lazy(() => import('./components/boat/Boat'));
const Events = lazy(() => import('./components/events/Events'));
const Event = lazy(() => import('./components/events/Event'));
const Sorted = lazy(() => import('./components/events/Sorted'));
const Contacts = lazy(() => import('./components/contacts/Contacts'));
const NotFound = lazy(() => import('./components/notFound/NotFound'));


const firebaseConfig = {
  apiKey: "AIzaSyAKNRssHROgM1HYHGLCVD71uj6_u-rbp5U",
  authDomain: "yatch-cruise-connect.firebaseapp.com",
  projectId: "yatch-cruise-connect",
  storageBucket: "yatch-cruise-connect.appspot.com",
  messagingSenderId: "651106916618",
  appId: "1:651106916618:web:a13ef58bdc0ceeb0f882f3",
  measurementId: "G-2ZZCQ5TFZ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);

const App = () => {
  // const [user, setUser] = useState(null);
  // // const myProfile = useSelector(state => state.auth.myProfile);
  // // const dispatch = useDispatch();

  // useEffect(() => {
  //   const unregisterAuthObserver = onAuthStateChanged(auth, user => {
  //     setUser(user ? true : false);
  //   });

  //   return () => unregisterAuthObserver(); // cleanup subscription on unmount
  // }, []);

  const renderAccess = () => {
    return (
      <Topbars>
        <div className="mainContainer">
          <Suspense fallback={
            <div className='center-placeholder'>
              <img src={logo} alt='logo' />
            </div>
          }>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/catalogue' element={<Catalogue />} />
              <Route path='/catalogue/boat/:boatId' element={<Boat />} />
              <Route path='/events' element={<Events />} />
              <Route path='/events/event/:eventId' element={<Event />} />
              <Route path='/events/:sortedCriteria' element={<Sorted />} />
              <Route path='/gallery' element={<Gallery />} />
              {/* <Route path='/picture/:id' element={<Picture />} /> */}
              <Route path='/contacts' element={<Contacts />} />
              {/* <Route path='/community' element={<Community />} /> */}
              {/* <Route path='/login' element={<Login />} /> */}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </div>
      </Topbars>
    );
  };


  return (
    <BrowserRouter>
      {/* {user !== null ? */}
      {renderAccess()}
      {/*  : <Spinner />} */}
    </BrowserRouter>
  );


};

export default App;
