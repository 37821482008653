import {combineReducers} from "redux";

//import reducers
import AuthReducer from "./AuthReducer";
import AnalyticsReducer from "./AnalyticsReducer";
import contactReducer from "./ContactReducer";
import BoatsReducer from "./BoatsReducer";
import EventsReducer from "./EventsReducer";
import BookingReducer from "./BookingReducer";
import PicturesReducer from "./PicturesReducer";

export default combineReducers({
    auth: AuthReducer,
    analytics: AnalyticsReducer,
    contact: contactReducer,
    boats: BoatsReducer,
    events: EventsReducer,
    booking: BookingReducer,
    pictures: PicturesReducer
});