// bookingReducer.js
const initialState = {
    bookingId: null,
    error: null,
    loading: false,
  };
  
  const BookingReducer = (state = initialState, action) => {
    switch (action.type) {
      case "BOOKING_SUCCESS":
        return { ...state, bookingId: action.payload, error: null, loading: false };
      case "BOOKING_FAILURE":
        return { ...state, bookingId: null, error: action.payload, loading: false };
      case "BOOKING_REQUEST":
        return { ...state, loading: true };
      default:
        return state;
    }
  };
  
  export default BookingReducer;
  