import React, { useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RxHamburgerMenu } from 'react-icons/rx';
import { logoutUser } from '../../actions/AuthActions';
import Logo from '../../assets/logo.png';
import { auth } from '../../App';
import './Topbars.css';
import { FaTimes } from 'react-icons/fa';

const TopNavbar = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const closeMenu = () => {
        setIsOpen(false);
    };

    const handleLogout = () => {
        logoutUser();
        closeMenu();
    };

    const menuItems = [
        { path: '/', name: 'Home' },
        { path: '/catalogue', name: 'Catalogue' },
        { path: '/events', name: 'Events' },
        { path: '/gallery', name: 'Gallery' },
        { path: '/contacts', name: 'Contacts' },
        // { path: '/community', name: 'Community' },
        // { path: '/login', name: 'Client Login' }
    ];

    return (
        <div className="container">
            <nav className="topnav">
                <div className="bars" onClick={toggleMenu}>
                    {/* <RxHamburgerMenu /> */}
                    {isOpen ?
                            <FaTimes />
                            :
                            <RxHamburgerMenu />
                        }
                </div>
                <div className="logo logoLargeScreen">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className="titleSmall">
                    <div className="ycc">YCC</div>
                </div>
                <div className="titleLarge">
                    <div className="ycc">Yatch Cruise Connect</div>
                </div>
                <div className="logo logoSmallScreen">
                    <img src={Logo} alt="Logo" />
                </div>
                <div className={`menu ${isOpen ? 'open' : ''}`}>
                    {menuItems.map((item, index) =>
                        auth?.currentUser && item.path === '/login' ? (
                            <div key={index} className="link logout" onClick={handleLogout}>
                                Logout
                            </div>
                        ) : (
                            <NavLink
                                to={item.path}
                                key={index}
                                className="link"
                                activeClassName="active"
                                onClick={closeMenu}
                            >
                                {item.name}
                            </NavLink>
                        )
                    )}
                </div>
            </nav>
            <main className='all-content-container'>{children}</main>
        </div>
    );
};

export default connect(null)(TopNavbar);
