import React from 'react';
import './Footer.css';
import { FaFacebook, FaInstagram, FaLinkedin } from 'react-icons/fa';
import tanzator from '../../assets/tanzator.png';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-section about">
                    <h2>About Us</h2>
                    <p>
                    Discover bespoke yatch experiences with cruise connect offering luxury cruises tailored to your desires. Embark on unforgettable journeys with our curated fleet and impeccable service                    </p>
                </div>
                <div className="footer-section explore">
                    <h2>Explore</h2>
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><a href="/catalogue">Catalogue</a></li>
                        <li><a href="/events">Events</a></li>
                        <li><a href="/gallery">Gallery</a></li>
                        <li><a href="/contacts">Contact Us</a></li>
                    </ul>
                </div>
                <div className="footer-section map">
                    <h2>Visit Us</h2>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d253568.33077612525!2d39.089472767127056!3d-6.769222473736852!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4bae169bd6f1%3A0x940f6b26a086a1dd!2sDar%20es%20Salaam%2C%20Tanzania!5e0!3m2!1sen!2sus!4v1718935083371!5m2!1sen!2sus"
                        width="100%"
                        height="200"
                        frameborder="0"
                        style={{ border: 0 }}
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="Yatch Cruise Connect"
                    ></iframe>
                </div>
                <div className="footer-section social">
                    <h2>Follow Us</h2>
                    <div className="footer-social-icons">
                        <a href="https://www.facebook.com/profile.php?id=61561473145600&mibextid=LQQJ4d" target="_blank" rel="noreferrer"><FaFacebook /></a>
                        {/* <a href="https://twitter.com" target="_blank" rel="noopener noreferrer"><FaTwitter /></a> */}
                        <a href="https://instagram.com/yatch.cruiseconnect" target="_blank" rel="noreferrer"><FaInstagram /></a>
                        <a href="http://linkedin.com/in/yatch-cruise-connect" target="_blank" rel="noreferrer"><FaLinkedin /></a>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>&copy; {new Date().getFullYear()} Yatch Cruise Connect. All Rights Reserved.</p>
                <p className="footer-bottom-tanzator">Powered by<a className="footer-bottom-tanzator-image" href="https://www.tanzator.com" target="_blank" rel="noopener noreferrer"><img src={tanzator} width={40} height={40} color='#bb4377'alt='Tanzator Logo'/>Tanzator</a></p>
            </div>
        </footer>
    );
};

export default Footer;
