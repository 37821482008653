import { message } from "antd";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, onAuthStateChanged, signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import {
    AUTH_FIELD_CHANGED,
    LOGIN_USER,
    LOGIN_USER_FAILED,
    LOGIN_USER_SUCCESSFUL,
    REGISTER_USER,
    REGISTER_USER_SUCCESSFUL,
    REGISTER_USER_FAILED,
    LOGOUT_USER,
    LOGOUT_USER_SUCCESSFUL,
    LOGOUT_USER_FAILED
} from "./Types";

// Function declaration
// function withRouter(Component) {
//     return props => <Component navHook={useNavigate()} />;
// }

export const authFieldChanged = ({ prop, value }) => {
    return {
        type: AUTH_FIELD_CHANGED,
        payload: { prop, value }
    };
};

export const loginUser = ({ email, password }) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_USER });
        const auth = getAuth();

        signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                message.success(`Welcome, ${user.email}!`);
                dispatch({ type: LOGIN_USER_SUCCESSFUL });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                message.error(`Login failed!`);
                console.log(errorCode);
                console.log(errorMessage);
                dispatch({ type: LOGIN_USER_FAILED });
            });
    };
};

export const registerUser = ({ email2, password2 }) => {
    return (dispatch) => {
        dispatch({ type: REGISTER_USER });
        const auth = getAuth();

        createUserWithEmailAndPassword(auth, email2, password2)
            .then(() => {
                onAuthStateChanged(auth, user => {
                    if (user) {
                        message.success(`${user.email}, registered successfully`);
                        dispatch({ type: REGISTER_USER_SUCCESSFUL });
                        const navigate = useNavigate();
                        navigate('/loginPage');
                    }
                });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                message.error(`There was an error registering user with email: ${email2}`);
                console.log(errorCode);
                console.log(errorMessage);
                dispatch({ type: REGISTER_USER_FAILED });
            });
    };
};

export const logoutUser = () => {
    return (dispatch) => {
        dispatch({ type: LOGOUT_USER });
        const auth = getAuth();

        signOut(auth)
            .then(() => {
                message.success(`Logged out successfully!`);
                dispatch({ type: LOGOUT_USER_SUCCESSFUL });
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                message.error(`Logout failed: TRY AGAIN!`);
                console.log(errorCode);
                console.log(errorMessage);
                dispatch({ type: LOGOUT_USER_FAILED });
            });
    };
};
