import { FETCH_EVENTS_FAILURE, FETCH_EVENTS_REQUEST, FETCH_EVENTS_SUCCESS } from '../actions/EventsActions';
import {
   
} from '../actions/Types';

const initialState = {
    events: [],
    loading: false,
    error: null,
    fetchingEvents: false,
};

const EventsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_EVENTS_REQUEST:
            return {
                ...state,
                fetchingEvents: true,
            };
        case FETCH_EVENTS_SUCCESS:
            return {
                ...state,
                events: action.payload,
                fetchingEvents: false,
            };
        case FETCH_EVENTS_FAILURE:
            return {
                ...state,
                fetchingEvents: false,
            };
       
        default:
            return state;
    }
};

export default EventsReducer;
