import { FETCH_BOATS, FETCH_BOATS_FAILURE, FETCH_BOATS_SUCCESS } from '../actions/BoatActions';
import {

} from '../actions/Types';

const initialState = {
    boats: [],
    loading: false,
    error: null,
    fetchingBoats: false,
};

const BoatsReducer = (state = initialState, action) => {
    switch (action.type) {
        case FETCH_BOATS:
            return {
                ...state,
                fetchingBoats: true,
            };
        case FETCH_BOATS_SUCCESS:
            return {
                ...state,
                boats: action.payload,
                fetchingBoats: false,
            };
        case FETCH_BOATS_FAILURE:
            return {
                ...state,
                fetchingBoats: false,
            };

        default:
            return state;
    }
};

export default BoatsReducer;
